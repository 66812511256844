import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Button, DataList,
    DataListCell,
    DataListItem,
    DataListItemCells,
    DataListItemRow,
    DrawerContentBody,
    Form,
    Modal,
    ModalVariant,
    Switch
} from "@patternfly/react-core";

import "./DetailsForm.css";

class UserDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            showConfirmDeleteUserModal: false
        }

        this.renderConfirmDeleteUserModal = this.renderConfirmDeleteUserModal.bind(this)
    }

    render() {
        return (
            <Translation>
                { t =>
                    <>
                        <DrawerContentBody className="DetailsDrawer">
                            <DataList isCompact aria-label="details">
                                <DataListItem>
                                    <DataListItemRow>
                                        <DataListItemCells dataListCells={[
                                            <DataListCell key="label" isFilled={false}>{t("fe_users_role")}</DataListCell>,
                                            <DataListCell key="value" isFilled={false} alignRight className="Value">
                                                <Switch
                                                    label={t("user_role_admin")}
                                                    labelOff={t("user_role_user")}
                                                    id="admin-switch"
                                                    isChecked={this.props.user["enumRole"] === "Admin"}
                                                    hasCheckIcon
                                                    isReversed
                                                    onChange={this.props.handleChangeUserRole}
                                                />
                                            </DataListCell>
                                        ]}/>
                                    </DataListItemRow>
                                </DataListItem>
                            </DataList>
                            <Form className="DetailsForm">
                                <ActionGroup className="DetailsActionGroup">
                                    <Button variant="danger"
                                            onClick={() => this.setState({showConfirmDeleteUserModal: true})}>
                                        {t("fe_users_delete-action")}
                                    </Button>
                                </ActionGroup>
                            </Form>
                            {
                                this.state.showConfirmDeleteUserModal && this.renderConfirmDeleteUserModal(t)
                            }
                        </DrawerContentBody>
                    </>
                }
            </Translation>
        )
    }

    renderConfirmDeleteUserModal(t) {
        return (
            <Modal
                variant={ModalVariant.small}
                title={t("fe_common_confirm-title")}
                isOpen={true}
                onClose={() => this.setState({showConfirmDeleteUserModal: false})}
                actions={[
                    <Button key="confirm" variant="primary" onClick={this.props.handleDeleteUser}>
                        {t("fe_users_delete-action")}
                    </Button>,
                    <Button key="cancel" variant="link" onClick={() => this.setState({showConfirmDeleteUserModal: false})}>
                        {t("fe_common_cancel")}
                    </Button>
                ]}
            >
                {t("fe_users_delete-hint")}
            </Modal>
        )
    }
}


export default UserDetails