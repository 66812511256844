import React, { Component } from "react";
import { Translation } from "react-i18next";

import {
    ActionGroup,
    Alert,
    Button,
    Card,
    Drawer,
    DrawerActions,
    DrawerCloseButton,
    DrawerColorVariant,
    DrawerContent,
    DrawerContentBody,
    DrawerHead,
    DrawerPanelContent,
    Dropdown,
    DropdownItem,
    DropdownToggle,
    Flex,
    FlexItem,
    Form,
    FormGroup,
    Label,
    PageGroup,
    PageSection,
    PageSectionVariants,
    Spinner,
    Tab,
    Tabs,
    TabTitleIcon,
    TabTitleText,
    Text,
    TextInput,
    TextVariants,
    Title,
    TitleSizes
} from "@patternfly/react-core";

import {
    TableComposable,
    Tbody,
    Td,
    Th,
    Thead,
    Tr
} from "@patternfly/react-table";

import {
    ListIcon,
    PlusIcon,
    SyncAltIcon
} from "@patternfly/react-icons";

import { AppSettings } from "./AppSettings";
import UserDetails from "./UserDetails";

import "./DetailsDrawer.css";

class UserList extends Component {
    static COLUMNS = [
        {field: "email", label: "fe_common_email", width: 25},
        {field: "localizedRole", label: "fe_users_role", width: 15},
        {field: "localizedLastLogin", label: "fe_users_last-login", width: 15},
        {field: "localizedLastAccess", label: "fe_users_last-access", width: 15},
        {field: "localizedCreatedAt", label: "fe_common_created-on", width: 15},
        {field: "createdBy", label: "fe_common_created-by", width: 15}

    ]
    static ROLES = [
        {enum: "", label: "fe_common_select-placeholder"},
        {enum: "User", label: "user_role_user"},
        {enum: "Admin", label: "user_role_admin"}
    ]

    static sortRows(rows, index, direction) {
        const field = UserList.COLUMNS[index].field === "localizedCreatedAt" ? "isoCreatedAt" :
            UserList.COLUMNS[index].field === "localizedLastLogin" ? "isoLastLogin" :
                UserList.COLUMNS[index].field === "localizedLastAccess" ? "isoLastAccess" :
                    UserList.COLUMNS[index].field
        return rows.sort((g1, g2) => {
            if (direction === "asc") {
                return g1[field].localeCompare(g2[field])
            }
            return g2[field].localeCompare(g1[field])
        })
    }

    constructor(props) {
        super(props)

        this.users = []

        this.state = {
            sortedUsers: [],
            activeTabKey: 0,
            selectedRowIndex: -1,
            activeSortIndex: 0,
            activeSortDirection: "asc",
            createUserEmail: "",
            createUserRole: "",
            createInProgress: false,
            successMessage: false
        }

        this.reloadUsers = this.reloadUsers.bind(this)
        this.onRowClick = this.onRowClick.bind(this)
        this.onSort = this.onSort.bind(this)
        this.onTabSelect = this.onTabSelect.bind(this)
        this.onToggleSelectRoleDropDown = this.onToggleSelectRoleDropDown.bind(this)
        this.isCreateButtonDisabled = this.isCreateButtonDisabled.bind(this)
        this.onCreate = this.onCreate.bind(this)
        this.changeUserRole = this.changeUserRole.bind(this)
        this.deleteUser = this.deleteUser.bind(this)
    }

    componentDidMount() {
        this.reloadUsers()
    }

    render() {
        const drawerExpanded = this.state.selectedRowIndex !== -1
        const user = drawerExpanded ? this.state.sortedUsers[this.state.selectedRowIndex] : {}
        const userEmail = drawerExpanded ? user["email"] : ""

        return (
            <Translation>
                { t =>
                    <>
                        <Drawer isExpanded={drawerExpanded} position="right">
                            <DrawerContent panelContent={
                                <DrawerPanelContent colorVariant={DrawerColorVariant.light200} minSize="600px">
                                    <DrawerHead>
                                        <Title headingLevel="h1">{t("fe_users_title")}</Title>
                                        <Text component={TextVariants.h6}>{userEmail}</Text>
                                        <DrawerActions>
                                            <DrawerCloseButton onClick={() => this.setState({selectedRowIndex: -1})}/>
                                        </DrawerActions>
                                    </DrawerHead>
                                    {
                                        drawerExpanded && <UserDetails
                                            user={user}
                                            handleChangeUserRole={(value) => this.changeUserRole(value)}
                                            handleDeleteUser={() => this.deleteUser()}
                                        />
                                    }
                                </DrawerPanelContent>
                            }>
                                <DrawerContentBody>
                                    <PageGroup>
                                        <PageSection variant={PageSectionVariants.light}>
                                            <Title className="Title" headingLevel="h1">{t("fe_users_title")}</Title>
                                            <Tabs activeKey={this.state.activeTabKey} onSelect={this.onTabSelect}
                                                  isBox={true}>
                                                <Tab eventKey={0} title={<><TabTitleIcon><ListIcon/></TabTitleIcon>
                                                    <TabTitleText>{t("fe_common_overview")}</TabTitleText></>}>
                                                    <Card component="div">
                                                        <TableComposable variant="compact" borders={true}
                                                                         isStickyHeader>
                                                            <Thead>
                                                                <Tr>
                                                                    {
                                                                        UserList.COLUMNS.map((column, index) => {
                                                                            const sortParams = {
                                                                                sort: {
                                                                                    sortBy: {
                                                                                        index: this.state.activeSortIndex,
                                                                                        direction: this.state.activeSortDirection
                                                                                    },
                                                                                    onSort: this.onSort,
                                                                                    columnIndex: index
                                                                                }
                                                                            };
                                                                            return <Th key={index} {...sortParams}
                                                                                       width={column.width}>{t(column.label)}</Th>
                                                                        })
                                                                    }
                                                                    <Th key="refresh" className="RefreshButton">
                                                                        <Button variant="plain" isInline
                                                                                aria-label="Refresh"
                                                                                onClick={this.reloadUsers}><SyncAltIcon/></Button>
                                                                    </Th>
                                                                </Tr>
                                                            </Thead>
                                                            <Tbody>
                                                                {
                                                                    this.state.sortedUsers.map((row, rowIndex) => {
                                                                        return (
                                                                            <Tr isHoverable key={rowIndex}
                                                                                onRowClick={event => this.onRowClick(event, rowIndex)}
                                                                                isRowSelected={this.state.selectedRowIndex === rowIndex}>
                                                                                <Td key={`${rowIndex}_0`}
                                                                                    dataLabel={t(UserList.COLUMNS[0].label)}>
                                                                                    {row["email"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_1`}
                                                                                    dataLabel={t(UserList.COLUMNS[1].label)}>
                                                                                    <Label
                                                                                        color={row["enumRole"] === "Admin" ? "red" : "green"}>{row["localizedRole"]}</Label>
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_2`}
                                                                                    dataLabel={t(UserList.COLUMNS[2].label)}>
                                                                                    {row["localizedLastLogin"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_3`}
                                                                                    dataLabel={t(UserList.COLUMNS[3].label)}>
                                                                                    {row["localizedLastAccess"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_4`}
                                                                                    dataLabel={t(UserList.COLUMNS[4].label)}>
                                                                                    {row["localizedCreatedAt"]}
                                                                                </Td>
                                                                                <Td key={`${rowIndex}_5`}
                                                                                    dataLabel={t(UserList.COLUMNS[5].label)}>
                                                                                    {row["createdBy"]}
                                                                                </Td>
                                                                            </Tr>
                                                                        )
                                                                    })
                                                                }
                                                            </Tbody>
                                                        </TableComposable>
                                                    </Card>
                                                </Tab>
                                                <Tab eventKey={1}
                                                     title={<><TabTitleIcon><PlusIcon/></TabTitleIcon> <TabTitleText>
                                                         {t("fe_users_add")}
                                                     </TabTitleText></>}>
                                                    {
                                                        this.state.successMessage ?
                                                            <Alert isInline className="TopSpacer" variant="success"
                                                                   title={this.state.successMessage}/> : false
                                                    }
                                                    <Form>
                                                        <Title headingLevel="h3" size={TitleSizes['2xl']}
                                                               className="TopSpacer"
                                                        >
                                                            {t("fe_users_add-title")}
                                                        </Title>
                                                        <Flex>
                                                            <FlexItem style={{width: '400px'}}>
                                                                <FormGroup label={t("fe_common_email")} fieldId="em"
                                                                           className="TopSpacer" isRequired>
                                                                    <TextInput type="text" id="em" name="em"
                                                                               value={this.state.createUserEmail}
                                                                               onChange={(value) => this.setState({createUserEmail: value})}/>
                                                                </FormGroup>
                                                                <FormGroup label={t("fe_users_role")} fieldId="role"
                                                                           className="TopSpacer" isRequired>
                                                                    <Dropdown
                                                                        onSelect={() => this.setState({selectRoleDropDownOpen: false})}
                                                                        toggle={
                                                                            <DropdownToggle
                                                                                id="role"
                                                                                onToggle={this.onToggleSelectRoleDropDown}
                                                                                style={{width: "500px"}}>
                                                                                {t(UserList.ROLES.find(role => role.enum === this.state.createUserRole).label)}
                                                                            </DropdownToggle>
                                                                        }
                                                                        isOpen={this.state.selectRoleDropDownOpen}
                                                                        dropdownItems={
                                                                            UserList.ROLES.filter((_, i) => i > 0).map((role, i) => {
                                                                                return (
                                                                                    <DropdownItem key={i}
                                                                                                  component="button"
                                                                                                  onClick={() => this.setState({createUserRole: role.enum})}>
                                                                                        {t(role.label)}
                                                                                    </DropdownItem>
                                                                                )
                                                                            })}
                                                                    />
                                                                </FormGroup>
                                                            </FlexItem>
                                                        </Flex>
                                                        <ActionGroup>
                                                            {
                                                                this.state.createInProgress ? <Spinner isSVG/> : (
                                                                    <Button
                                                                        isDisabled={this.isCreateButtonDisabled()}
                                                                        variant="primary"
                                                                        onClick={() => this.onCreate(t)}
                                                                    >
                                                                        {t("fe_users_add-action")}
                                                                    </Button>
                                                                )
                                                            }
                                                        </ActionGroup>
                                                    </Form>
                                                </Tab>
                                            </Tabs>
                                        </PageSection>
                                    </PageGroup>
                                </DrawerContentBody>
                            </DrawerContent>
                        </Drawer>
                    </>
                }
            </Translation>
        )
    }

    reloadUsers() {
        const requestInit = {
            cache: "no-cache",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`
            }
        }
        fetch(`${AppSettings.rootPath}/v1/users/list`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(data => {
                            this.users = data
                            this.setState(prevState => {
                                return {
                                    selectedRowIndex: -1,
                                    sortedUsers: UserList.sortRows(data, prevState.activeSortIndex, prevState.activeSortDirection)
                                }
                            })
                        })
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    onRowClick(event, rowIndex) {
        this.setState(prevState => {
            return {
                selectedRowIndex: prevState.selectedRowIndex === rowIndex ? -1 : rowIndex
            }
        })
    }

    onSort(event, index, direction) {
        this.setState({
            selectedRowIndex: -1,
            activeSortIndex: index,
            activeSortDirection: direction,
            sortedUsers: UserList.sortRows(this.users, index, direction)
        })
    }

    onTabSelect(event, tabIndex) {
        this.setState({
            activeTabKey: tabIndex,
            selectedRowIndex: -1
        })
        if (tabIndex === 0) {
            this.reloadUsers()
        }
    }

    onToggleSelectRoleDropDown() {
        this.setState(prevState => {
            return {
                selectRoleDropDownOpen: !prevState.selectRoleDropDownOpen
            }
        })
    }

    isCreateButtonDisabled() {
        return !this.state.createUserEmail || !this.state.createUserEmail.includes("@")  || !this.state.createUserRole
    }

    onCreate(t) {
        this.setState({createInProgress: true})
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                email: this.state.createUserEmail,
                role: this.state.createUserRole
            })
        }
        fetch(`${AppSettings.rootPath}/v1/users/create`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 201) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState(prevState => {
                        return {
                            successMessage: t("fe_users_add-success").replace("%", prevState.createUserEmail),
                            createUserEmail: "",
                            createUserRole: ""
                        }
                    })
                }
            })
            .catch(error => this.setState({successMessage: false}, () => this.props.app.showErrorMessage(error.message)))
            .finally(() => this.setState({createInProgress: false}))
    }

    changeUserRole(changeToAdmin) {
        const email = this.state.sortedUsers[this.state.selectedRowIndex].email
        const requestInit = {
            cache: "no-cache",
            method: "POST",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                role: changeToAdmin ? "Admin" : "User"
            })
        }
        fetch(`${AppSettings.rootPath}/v1/users/${email}/role`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (!response.ok) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    response.json()
                        .then(userListItem => this.setState(prevState => {
                            prevState.sortedUsers[this.state.selectedRowIndex] = userListItem
                            this.user = prevState.sortedUsers
                            return { sortedUsers: prevState.sortedUsers }
                        }))
                        .catch(error => console.log(error.message))
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }

    deleteUser() {
        const email = this.state.sortedUsers[this.state.selectedRowIndex].email
        const requestInit = {
            cache: "no-cache",
            method: "DELETE",
            headers: {
                "Authorization": `Bearer ${this.props.app["jwt"]}`,
                "Content-Type": "application/json"
            }
        }
        fetch(`${AppSettings.rootPath}/v1/users/${email}`, requestInit)
            .then(response => {
                if (response.status === 401) {
                    this.props.app.sessionExpired()
                } else if (response.status !== 204) {
                    response.text().then(message => this.props.app.showErrorMessage(message || response.statusText))
                } else {
                    this.setState(prevState => {
                        prevState.sortedUsers.splice(this.state.selectedRowIndex, 1)
                        this.user = prevState.sortedUsers
                        return {
                            sortedUsers: prevState.sortedUsers,
                            selectedRowIndex: -1
                        }
                    })
                }
            })
            .catch(error => this.props.app.showErrorMessage(error.message))
    }
}

export default UserList